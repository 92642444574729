<template>
  <div>
    <CContainer fluid>
      <CRow>
        <CCol sm="6">
          <h2>Mother Category</h2>
          <multiselect
            v-model="mother"
            :options="rootCat.children"
            placeholder="Select one"
            label="name"
          >
          </multiselect>
          <img style="margin-top:15px;" src="https://storcpdkenticomedia.blob.core.windows.net/media/recipemanagementsystem/media/recipe-media-files/recipes/retail/x17/2020_belgian-style-waffles_16700_760x580.jpg?ext=.jpg">
        </CCol>
        <CCol sm="4">
          <h2>File Select</h2>
          <CInputFile :disabled="!mother" @change="fileUpload" accept=".csv"></CInputFile>
          <small><strong>{{ products.length}} products to process</strong></small><br/>
          <CButton @click="doThing" :disabled="!products.length" color="primary">Do Thing</CButton>
        </CCol>
        <CCol sm="2">
          <CToaster :autohide="3000">
            <template v-for="(toast, i) in toasts">
              <CToast
                :key="i"
                :show="true"
                header="Thing update"
              >
              {{ toast }}
              </CToast>
            </template>
          </CToaster>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { getCategories, postCategory } from '@/api/categories'
import { postProducts } from '@/api/products'
export default {
  name: "AddProducts",
  components: {
    Multiselect
  },
  data: () => ({
    rootCat: {children: []},
    products: [],
    processed: [],
    parsedCategories: [],
    postCategory: false,
    toasts: [],
    mother: ''
  }),
  mounted() {
    this.getCategories()
  },
  methods: {
    async getCategories() {
      const root = (await getCategories())[0]
      this.rootCat = root
    },
    async doThing() {
      try {
        this.parsedCategories = [{...this.rootCat}]; //copy
        this.toasts.push('Processing products')
        this.processProds()
        if (this.postCategory) {
          this.toasts.push('POSTing categories')
          await postCategory(this.parsedCategories)
        }

        this.toasts.push('POSTing products')
        await postProducts(this.processed)
        this.toasts.push(`Added ${this.processed.length} products.`)

      } catch (err) {
        alert(err.message)
      }
    },
    getCat(category) {
      const categories = [this.mother, ...category.split(' > ').map(c => {
        return {
          name: c.toLowerCase().split(' ').join('_'),
          displayName: c,
        }
      })]

      let c = this.parsedCategories[0]
      for (let idx=0; idx<categories.length; idx++) {
        if (!c.children) c.children = []
        const ct = c.children.findIndex(({ name }) => name === categories[idx].name);
        if (ct !== -1) c = c.children[ct]
        else {
          this.postCategory = true
          for (; idx<categories.length; idx++) {
            if (!c.children) c.children = []
            c.children.push(categories[idx])
            c = categories[idx]
          }
          break
        }
      }

      return c
    },
    processProds() {
      this.processed = [];

      for (const line of this.products) {
        const images = [line[4]]
        if (line[15]) images.push(line[15])

        this.processed.push({
          sid: line[2],
          url: line[0],
          name: line[1].replace(/(^")|("$)/g, ''),
          images,
          description: line[5].replace(/(^")|("$)/g, ''),
          brand: {
            sid: line[10],
            name: line[9].replace(/(^")|("$)/g, ''),
          },
          category: { sid: this.getCat(line[6].replace(/(^")|("$)/g, '')).sid },
          currency: line[8],
          price: Number(line[7]),
          oldPrice: Number(line[12]),
          savingsPercent: Number(line[11]),
          isForSale: line[13] === '1',
          inStock: line[14] === '1'
        })
      }
    },
    fileUpload([file]) {
      const reader = new FileReader();
      reader.onload = async e => {
        const lines = e.target.result.split('\n');

        const schema = 'aw_deep_link|product_name|aw_product_id|merchant_product_id|merchant_image_url|description|merchant_category|search_price|currency|brand_name|merchant_id|savings_percent|product_price_old|is_for_sale|in_stock|alternate_image';
        if (lines[0] !== schema) {
          alert(`CSV Schema is different. Expected "${schema}" and got "${lines[0]}"`)
          return
        }

        lines.shift()

        for (const line of lines) {
          if (line.length === 0 || (line.match(/\|/g) || []).length !== 15) continue;
          const splitted = line.split('|')

          const required = [2, 0, 1, 4, 5, 10, 9, 6, 8, 7];
          let skip = false;
          for (const r in required)
            if (!splitted[r].length) {
              skip = true;
              break;
            }
          
          if (skip) continue;

          this.products.push(splitted)
        }
      }

      reader.readAsText(file)
    }
  }
}
</script>

<style scoped>

</style>
